import { Component } from '@angular/core';
  import { FormBuilder, FormGroup, Validators } from '@angular/forms';
  import { NGXLogger } from 'ngx-logger';
  import { ConfirmResetService } from 'projects/petszel-shared/src/lib/services/confirm-reset.service';
import { AnimalResponse, BrandingResponse } from '../../models/branding.interface';
import { BusinessNameService } from '../../service/businessName.service';
import { OwnerService } from '../../service/owner-logo.service';
import { OwnerFamilyService } from '../../service/owner-family.service';
@Component({
  selector: 'app-email-reset',
  templateUrl: './email-reset.component.html',
  styleUrls: ['./email-reset.component.css']
})
export class EmailResetComponent {
  formResetEmail!: FormGroup;
  submitted = false;
  showResetEmailAlert = false;
  errorMsg!: string;
  shelterLogoSrc!: string;
  ShelterAlt!: string;
  size!: string;
  brandingDays!: number;
  daysSinceAdoption!: number;

  constructor(
    private formBuilder: FormBuilder,
    private logger: NGXLogger,
    private postReset: ConfirmResetService,
    private legalService: BusinessNameService,
    private logo: OwnerService,
    private owner: OwnerFamilyService
  ){}

  ngOnInit(): void {
    this.fetchShelterLogoSrc();
    this.formResetEmail = this.formBuilder.group(
      {
        username: ['', [Validators.compose([Validators.required, Validators.email])]],
      },
    );
  }

  async fetchShelterLogoSrc() {
    try {
      const brandingResponse = (await this.logo
        .getBranding()
        .toPromise()) as BrandingResponse;
      this.brandingDays = brandingResponse.configuration.shelterBrandingDays;
      const defaultLogoId = brandingResponse.configuration.defaultLogoId;

      this.legalService.businessName = brandingResponse.businessName;
      this.logger.log(this.legalService);

      const animalResponse = (await this.owner
        .getAnimalInfo()
        .toPromise()) as AnimalResponse[];
      this.daysSinceAdoption = animalResponse[0]?.daysSinceAdoption; // Using optional chaining

      if (this.daysSinceAdoption !== undefined) {
        if (this.daysSinceAdoption <= this.brandingDays) {
          const response = await this.logo.getLogo().toPromise();
          if (response !== undefined && response.length > 0) {
            const logo = response.find((l) => l.logoId === defaultLogoId); // Step 2
            if (logo) {
              this.shelterLogoSrc = logo.logoUrl; // Step 3
            } else {
              this.shelterLogoSrc = response[0].logoUrl; // Fallback to the first logo
              this.logger.warn(
                'Default logo not found. Fallback to the first logo.'
              );
            }
          } else {
            this.logger.error('No logos found.');
          }
        } else {
          this.logger.log(
            'Days since adoption exceed branding days. Skipping logo fetch.'
          );
        }
      } else {
        this.logger.error('No animal information found.');
      }
    } catch (error) {
      this.logger.error('An error occurred while fetching data:', error);
    }
  }

  onSubmit(): void {
    this.submitted = true;
  
    if (this.formResetEmail.invalid) {
      return;
    }
  
    this.postReset.postResetPassword(this.formResetEmail.value).subscribe(
      (result) => {
        this.showResetEmailAlert = true;
      },
      (error) => {
        if (error.status === 400) {
          this.errorMsg = "Email is not verified. Please use the email found in Manage Account.";
        }
      }
    );
  
    this.formResetEmail.reset();
  }
  
}