import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";
import { NGXLogger } from "ngx-logger";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SharedDataService } from "../../service/shared-data.service";
import { SharedLibraryService } from "../../../../../petszel-shared/src/lib/services/shared.config";
import { OwnerFamilyService } from "../../service/owner-family.service";
import { Owner } from "../../models/communications.interface";

@Component({
  selector: 'app-terms-and-conditions-modal',
  templateUrl: './terms-and-conditions-modal.component.html',
  styleUrls: ['./terms-and-conditions-modal.component.css']
})
export class TermsAndConditionsModalComponent implements OnInit, OnDestroy {
  ownerInfo!: any;
  termsOfServiceURL: string = '';
  loading = false;

  private termsOfServiceSubs!: Subscription;
  private manageOwnerSubs!: Subscription;

  constructor(private logger: NGXLogger,
              private activeModalService: NgbActiveModal,
              private sharedDataService: SharedDataService,
              private ownerFamilyService: OwnerFamilyService,
              private sharedLibraryService: SharedLibraryService) {
  }

  ngOnInit(): void {
    this.sharedDataService.getOwnerInfo().subscribe(ownerInfo => this.ownerInfo = ownerInfo);
    this.buildTOSUrl();
  }

  ngOnDestroy(): void {
    if (this.termsOfServiceSubs) { this.termsOfServiceSubs.unsubscribe(); }
  }

  acceptTerms(): void {
    this.loading = true;
    const ownerData: Owner = {
      ...this.ownerInfo as Owner,
      acceptedTerms: true
    };

    this.manageOwnerSubs = this.ownerFamilyService.putOwnerInfo(ownerData).subscribe({
      next: () => {
        this.ownerFamilyService.getOwnerInfo().subscribe({
          next: info => {
            this.loading = false;
            this.sharedDataService.setOwnerInfo(info);
            this.activeModalService.close();
          },
          error: () => {
            location.reload();
          }
        })
      },
      error: err => {
        this.loading = false;
        this.logger.log('Error while accepting terms of service', err);
      }
    });
  }

  private buildTOSUrl(): void {
    const environment = this.sharedLibraryService.getSharedLibraryConfig().environmentType;
    if (environment === 'local' || environment === 'development') {
      this.termsOfServiceURL = 'https://dev.petszel.com/terms-of-service';
    } else if (environment === 'beta') {
      this.termsOfServiceURL = 'https://beta.petszel.com/terms-of-service';
    } else {
      this.termsOfServiceURL = 'https://petszel.com/terms-of-service';
    }
  }
}
