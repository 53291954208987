<div class="row margin-comm mt-5">
    <div class="col-12 d-none d-md-block">
      <ng-container
        *ngIf="shelterLogoSrc && this.daysSinceAdoption < this.brandingDays"
      >
        <lib-petszel-powered
          [shelterLogoSrc]="shelterLogoSrc"
          [ShelterAlt]="ShelterAlt"
          class="brand-logo-family"
          size="large"
        ></lib-petszel-powered>
      </ng-container>
      <ng-container
        *ngIf="!shelterLogoSrc && this.daysSinceAdoption >= this.brandingDays"
      >
        <lib-petszel-powered-nobrand
          [shelterLogoSrc]="shelterLogoSrc"
          [ShelterAlt]="ShelterAlt"
          class="brand-logo-family"
          size="large"
        ></lib-petszel-powered-nobrand>
      </ng-container>
    </div>
  </div>
  <div class="row d-flex justify-content-center">
    <div class="col-xl-6">
        <h3 class="pass">Create Password</h3>

    </div>
  </div>
<div class="row create">
  <div class="col-12">
    <div class="row d-flex justify-content-center align-items-center reset">
      <form
        class="col-xxl-3 col-sm-5 col m-5 box-border"
        [formGroup]="formResetEmail"
        (ngSubmit)="onSubmit()"
      >
        <div
          class="row d-flex flex-column align-items-center text-center padding-petszel-logo-reset"
        >
          <div class="col-sm-6 col">
            <img class="logo" src="../../assets/images/petszelLogo.png" />
          </div>
        </div>
        <div class="row d-flex flex-column align-items-center text-center">
          <div class="col-sm-8 col">
            <h3 class="font">Create Password</h3>
          </div>
        </div>
        <div class="row d-flex justify-content-center">
          <div class="col-sm-12 col d-flex justify-content-center">
            <div class="form-group">
              <label for="username" class="reset-email"
                >Enter account's verified email address to receive a password
                reset link.</label
              >

              <input
                formControlName="username"
                type="username"
                class="form-control"
                id="username"
                placeholder="JohnDoe@example.com"
                required
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col d-flex justify-content-center mt-5">
            <input
              class="btn btn-primary d-flex justify-content-center"
              type="submit"
              value="Create Password"
            />
          </div>
        </div>
      </form>
    </div>
    <div class="row d-flex justify-content-center">
        <div
          *ngIf="showResetEmailAlert"
          class="col-md-5 col-12 alert alert-secondary text-center"
          role="alert"
        >
          Please check your email to create your password.
        </div>
      </div>
      <div class="row d-flex justify-content-center">
        <div
          *ngIf="errorMsg"
          class="col-md-5 col-12 alert alert-danger text-center"
          role="alert"
        >
        {{errorMsg}}

        </div>
      </div>
  </div>
</div>
