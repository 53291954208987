import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { appConfig } from '../../owner.config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NationalbrandService {
  public token = localStorage.getItem('token');
  public headers: HttpHeaders;

  constructor(private http: HttpClient) {
    if (this.token) {
      this.headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    } else {
      this.headers = new HttpHeaders();
    }
  }

  getExclusive(): Observable<any> {
    const url = `${appConfig.apiUrl}Owner/Shelter/NationalBrandAssociations`;
    return this.http.get<any[]>(url, { headers: this.headers });
  }
}
