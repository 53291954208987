import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  private ownerInfoBS$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor() { }

  setOwnerInfo(info: any): void {
    this.ownerInfoBS$.next(info);
  }

  getOwnerInfo(): Observable<any> {
    return this.ownerInfoBS$.asObservable();
  }
}
